import { useEffect, useState } from "react";
import {
  Button,
  Table,
  Pagination,
  Container,
  Icon,
  Menu,
  Search,
  Modal,
  TextArea,
  Loader
} from "semantic-ui-react";
import { getUsers, updateRemark } from "../../services/api/users";
import UserModal from "./UserModal";
import ConfirmationModal from "./ConfirmationModel";
import _ from "lodash";

export default function UserList() {
  const [users, setUsers] = useState([]);
  const [activePage, setActivePage] = useState(1);
  const [updateInfo, setUpdateInfo] = useState({});
  const [showModel, setShowModel] = useState(false);
  const [totalPages, setTotalPages] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [searchValue, setSearhValue] = useState("");
  const [openRemarkUpdate, setOpenRemarkUpdate] = useState(false);
  const [remark, setRemark] = useState("");
  const [currRow, setCurrRow] = useState();
  const [loading, setLoading] = useState(true);
  const ITEMSPERPAGE = 20;

  useEffect(() => {
    getUsers().then((res) => {
      setUsers(res.results);
      setTotalPages(Math.ceil(res.count / ITEMSPERPAGE));
      setLoading(false);
    });
  }, [showModel, updateInfo, openRemarkUpdate]);

  const handlePageChange = (e, { activePage }) => {
    setActivePage(activePage);
  };

  const getPageData = () => {
    const startIndex = (activePage - 1) * ITEMSPERPAGE;
    const endIndex = startIndex + ITEMSPERPAGE;
    return users?.slice(startIndex, endIndex);
  };

  const closeModal = () => {
    setShowModel(false);
  };

  const openUpdateModal = (uid) => {
    var updateObj = { showUpdateModel: true, user: uid };
    setUpdateInfo(updateObj);
  };

  const closeUpdateModal = () => {
    var updateObj = { showUpdateModel: false, user: null };
    setUpdateInfo(updateObj);
  };

  const openConfirmationModal = (id) => {
    var delObj = { showDeleteConfirationModel: true, user: id };
    setUpdateInfo(delObj);
  };

  const closeConfirmationModal = () => {
    var delObj = { showDeleteConfirationModel: false, user: null };
    setUpdateInfo(delObj);
    closeModal();
  };

  const handleSearch = (value) => {
    setSearhValue(value);
    delayedQuery(value);
  };
  const delayedQuery = _.debounce((value) => {
    setIsLoading(true);
    getUsers(value).then((res) => {
      setUsers(res.results);
      setTotalPages(Math.ceil(res.count / ITEMSPERPAGE));
      setIsLoading(false);
    });
  }, 500);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        position: "absolute",
        right: 0,
        top: 0,
        width: "90%",
      }}
    >
      <Container as={Menu} style={{ width: "100%" }}>
        <Menu.Item position="right">
          <Icon name="user circle" />
          Admin
        </Menu.Item>
      </Container>
      <Container style={{ width: "100%", alignSelf: "end", padding: "10px" }}>
        <Search
          loading={isLoading}
          onSearchChange={(e, { value }) => {
            handleSearch(value);
          }}
          value={searchValue}
          showNoResults={false}
          placeholder="seacr users..."
        />
      </Container>
      { loading ? 
       <div style={{position:"fixed", top:"30%", left:"50%", zIndex:"1000"}}><Loader active content='Loading' size="large" /></div>
      : <div>
      <Container style={{ width: "100%", padding: "10px" }}>
        <Table celled className="center aligned">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>S.No</Table.HeaderCell>
              <Table.HeaderCell>Name</Table.HeaderCell>
              <Table.HeaderCell>Created</Table.HeaderCell>
              <Table.HeaderCell>MobileNo</Table.HeaderCell>
              <Table.HeaderCell>Zip</Table.HeaderCell>
              <Table.HeaderCell>Remarks</Table.HeaderCell>
              <Table.HeaderCell></Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {getPageData().map((user, idx) => (
              <Table.Row key={idx}>
                <Table.Cell>
                  {(activePage - 1) * ITEMSPERPAGE + idx + 1}
                </Table.Cell>
                <Table.Cell>
                  <div>
                    <img
                      className="ui avatar image"
                      src={user.partner?.profile_photo}
                      alt=""
                    />
                    <span>{user.name}</span>
                  </div>
                </Table.Cell>
                <Table.Cell>
                  {new Date(user.created).toLocaleDateString()}
                </Table.Cell>
                <Table.Cell>{user.mobile_number}</Table.Cell>
                <Table.Cell>{user.partner?.pincode}</Table.Cell>
                <Table.Cell>{user.remarks}</Table.Cell>
                <Table.Cell>
                  {user.is_staff ? (
                    <></>
                  ) : (
                    <>
                      <Button
                        icon
                        id={user.id}
                        positive
                        onClick={(e) => openUpdateModal(e.target.id)}
                      >
                        <Icon id={user.id} name="edit" />
                      </Button>
                      <Button
                        icon
                        id={user.id}
                        onClick={(e) => openConfirmationModal(e.target.id)}
                        negative
                      >
                        <Icon id={user.id} name="user delete" />
                      </Button>
                      <Button
                        icon
                        id={user.id}
                        onClick={(e) => {
                          setOpenRemarkUpdate(true);
                          setRemark(e.target.attributes["data"]?.value);
                          setCurrRow(e.target.id);
                        }}
                        positive
                        data={user.remarks}
                      >
                        Update Remarks
                      </Button>
                    </>
                  )}
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </Container>
      <Container textAlign="center">
        <Pagination
          className="right float"
          activePage={activePage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
        />
      </Container>
      </div>}
      <UserModal open={showModel} closeModal={closeModal} />
      <UserModal
        open={updateInfo.showUpdateModel}
        closeModal={closeUpdateModal}
        uid={updateInfo.user}
      />
      <ConfirmationModal
        open={updateInfo.showDeleteConfirationModel}
        closeModal={closeConfirmationModal}
        id={updateInfo.user}
      />
      <Modal
        className="scrolling"
        size="tiny"
        open={openRemarkUpdate}
        closeIcon
        onClose={() => setOpenRemarkUpdate(false)}
      >
        <Modal.Header>Update Remarks</Modal.Header>
        <Modal.Content>
          <div style={{ display: "flex" }}>
            <TextArea
              value={remark}
              onChange={(e) => setRemark(e.target.value)}
            ></TextArea>
            <Button
              positive
              onClick={() =>
                updateRemark(currRow, remark).then((res) =>
                  setOpenRemarkUpdate(false)
                )
              }
            >
              Update
            </Button>
          </div>
        </Modal.Content>
      </Modal>
    </div>
  );
}
