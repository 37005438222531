import { Leads, LeadsService, LeadCreate } from "../openapi";

const {
  leadsList,
  leadsRead,
  leadsPartialUpdate,
  leadsCreate
} = LeadsService;

export const getLeads = async (limit?: number, offset? : number) => {
  try {
    return await leadsList(limit,offset);
  } catch (error) {
    throw new Error("error");
  }
};

export const getLeadById = async (id: string): Promise<Leads> => {
  try {
    return await leadsRead(id);
  } catch (error) {
    throw new Error("error");
  }
};

export const updateLead = async (
  id: string,
  data: Leads
): Promise<Leads> => {
  try {
    return await leadsPartialUpdate(id, data);
  } catch (error) {
    throw new Error("error");
  }
};

export const createLead = async (
  data: Array<LeadCreate>
): Promise<Array<LeadCreate>> => {
  try {
    return await leadsCreate(data);
  } catch (error) {
    throw new Error("error");
  }
};

