import React, { useEffect, useState } from "react";
import { Button, Form, Input, Icon, Checkbox } from "semantic-ui-react";
import {
  addCategory,
  getCategoryById,
  updateCategory,
} from "../../services/api/categories";

const Category = ({ cid, closeModal }) => {
  const [category, setCategory] = useState("");
  const [loading, setLoading] = useState(false);
  const [selectedImage, setSelectedImage] = useState();
  const [isFeatured, setIsFeatured] = useState(false);
  const [isPopular, setIsPopular] = useState(false);
  const [isTop10, setIsTop10] = useState(false);
  const [preview, setPreview] = useState();

  const handleImageChange = (event) => {
    let file = event.target.files[0];
    setSelectedImage(file);
    setPreview(URL.createObjectURL(file));
    return () => {
      URL.revokeObjectURL(preview);
    };
  };

  useEffect(() => {
    if (cid) {
      getCategoryById(cid).then((res) => {
        setCategory(res.name);
        setSelectedImage(res.image);
        setIsFeatured(res.is_featured);
        setIsPopular(res.is_popular);
        setIsTop10(res.is_top_ten);
      });
    }
  }, [cid]);

  const create = () => {
    setLoading(true);
    addCategory({
      name: category,
      image: selectedImage,
      is_featured: isFeatured,
      is_popular: isPopular,
      is_top_ten: isTop10
    })
      .then(() => {
        setLoading(false);
        closeModal();
      })
      .catch((err) => console.log(err));
  };

  const update = () => {
    setLoading(true);
    var reqObj = {
      name: category,
      is_featured: isFeatured,
      is_popular: isPopular,
      is_top_ten: isTop10
    };
    if (!selectedImage?.toString().startsWith("http"))
      reqObj.image = selectedImage;
    updateCategory(cid, reqObj)
      .then(() => {
        setLoading(false);
        closeModal();
      })
      .catch((err) => console.log(err));
  };

  return (
    <Form>
      <Form.Field>
        <label>Name</label>
        <input
          placeholder="Name"
          value={category}
          onChange={(e) => setCategory(e.target.value)}
        />
      </Form.Field>
      <Form.Field>
        {selectedImage ? (
          <div>
            <img src={preview || selectedImage} alt="category logo" />
            <Button icon onClick={() => setSelectedImage(null)}>
              <Icon name="trash"></Icon>
            </Button>
          </div>
        ) : (
          <Input
            type="file"
            action
            onChange={handleImageChange}
            value={selectedImage}
          >
            <input />
            <Button icon>
              <Icon name="attach" />
            </Button>
          </Input>
        )}
      </Form.Field>
      <Form.Field>
        <Checkbox
          label="Is Featured"
          checked={isFeatured}
          onChange={() => setIsFeatured(!isFeatured)}
        />
      </Form.Field>
      <Form.Field>
        <Checkbox
          label="Is Popular"
          checked={isPopular}
          onChange={() => setIsPopular(!isPopular)}
        />
      </Form.Field>
      <Form.Field>
        <Checkbox
          label="Is Top10"
          checked={isTop10}
          onChange={() => setIsTop10(!isTop10)}
        />
      </Form.Field>
      {cid ? (
        <Button type="submit" onClick={update} disabled={!selectedImage}>
          {loading && <i className="loading spinner icon"></i>}Update
        </Button>
      ) : (
        <Button type="submit" onClick={create}>
          {loading && <i className="loading spinner icon"></i>}Create
        </Button>
      )}
    </Form>
  );
};

export default Category;
