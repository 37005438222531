import { Modal } from "semantic-ui-react";
import Category from "./Category";

const CategoryModal = (props) => {
  return (
    <Modal
      className="scrolling"
      size="tiny"
      open={props.open}
      closeIcon
      onClose={props.closeModal}
    >
      <Modal.Header>Category</Modal.Header>
      <Modal.Content>
        <Category cid={props.cid} closeModal={props.closeModal} />
      </Modal.Content>
    </Modal>
  );
};

export default CategoryModal;
