import React, { useEffect, useState } from "react";
import { Button, Form, Input, Icon, Container, Menu } from "semantic-ui-react";
import {
    getStats
} from "../../services/api/stats";

const Dashboard = () => {

    const [stats, setStats] = useState();
    const color = ['red', 'green', 'blue', 'teal']
    const icon = { 'customers': 'users', 'partners': 'handshake', 'leads': 'random', 'reviews': 'chart line' }

    const card = (param, value, clr) => (<div class="card">
        <div class="content">
            <div class="header">
                <div class="ui header meta">
                    {param}
                </div>
            </div>
        </div>
        <div class="extra content">
            <div class={`ui statistic ${clr}`}>
                <div class="value">
                    {value}
                </div>
            </div>
        </div>
    </div>)

    useEffect(() => {
        getStats().then(res => {
            let arr = []
            for (let stat in res) {
                res[stat].key = stat;
                arr.push(res[stat])
            }
            setStats(arr)
        })
    }, []);

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                position: "absolute",
                right: 0,
                top: 0,
                width: "90%",
            }}
        >
            <Container as={Menu} style={{ width: "100%" }}>
                <Menu.Item position="right">
                    <Icon name="user circle" />
                    Admin
                </Menu.Item>
            </Container>
            <Container style={{ width: "100%", padding: "10px" }}>
                <div class="ui grid stackable padded">
                    {stats?.map((stat, idx) => {
                        return (<div class="four wide computer eight wide tablet sixteen wide mobile column">
                            <div class="ui fluid card">
                                <div class="content">
                                    <div class={`ui right floated header ${color[idx]}`}>
                                        <i class={`icon ${icon[stat?.key]}`}></i>
                                    </div>
                                    <div class="header">
                                        <div class={`ui ${color[idx]} header`}>
                                            {stat?.all_time}
                                        </div>
                                    </div>
                                    <div class="meta">
                                        {stat?.key}
                                    </div>
                                </div>
                                <div class="extra content">
                                    <div class="ui two cards">
                                        {card("Today", stat?.today, color[idx])}
                                        {card("Month", stat?.this_month, color[idx])}
                                    </div>
                                </div>
                            </div>
                        </div>)
                    })}
                </div>
            </Container>
        </div>
    );
};

export default Dashboard;
