import { useEffect, useState } from "react";
import {
  Button,
  Form,
  Input,
  Grid,
  Divider,
  Header,
  Segment,
  Checkbox,
  Icon,
  Dropdown,
} from "semantic-ui-react";
import {
  addUser,
  getUserById,
  updateUser,
  addPartner,
  updatePartner,
} from "../../services/api/users";
import { getPlaces,getDistricts } from "../../services/api/place";
import { getCategories } from "../../services/api/categories";
import { SemanticToastContainer, toast } from "react-semantic-toasts";

const Create = ({ uid, closeModal }) => {
  const [user, setUser] = useState({});
  const [partner, setPartner] = useState({});
  const [loading, setLoading] = useState(false);
  const [profilePhotoPreview, setProfilePhotoPreview] = useState();
  const [coverPhotoPreview, setCoverPhotoPreview] = useState();
  const [idProof1Preview, setIdProof1Preview] = useState();
  const [idProof2Preview, setIdProof2Preview] = useState();
  const [places, setPlaces] = useState();
  const [districts, setDistricts] = useState();
  const [category, setCategory] = useState("");
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    getCategories().then((res) => {
      const formattedOptions = res.results.map((item) => ({
        key: item.id,
        text: item.name,
        value: item.id,
      }));
      setCategories(formattedOptions);
    });
    getPlaces().then((res) => {
      const formattedOptions = res.results.map((item) => ({
        key: item.id,
        text: item.area + ", " + item.zipcode,
        value: item.id,
      }));
      setPlaces(formattedOptions);
    });
    getDistricts().then((res) => {
      const formattedOptions = res.results.map((item) => ({
        key: item.id,
        text: item.name,
        value: item.id,
      }));
      setDistricts(formattedOptions);
    });
    if (uid) {
      getUserById(uid).then((res) => {
        setUser(res);
        setPartner(res.partner);
        setCategory(res.partner?.category);
      });
    }
    return () => {
      URL.revokeObjectURL(coverPhotoPreview);
      URL.revokeObjectURL(profilePhotoPreview);
      URL.revokeObjectURL(idProof1Preview);
      URL.revokeObjectURL(idProof2Preview);
    };
  }, [uid]);

  const handleDropdownChange = (event, data) => {
    setPartner((prevFormData) => ({
      ...prevFormData,
      ["location"]: data.value,
    }));
  };

  const handleImageChange = (e) => {
    const { name } = e.target;
    const value = e.target.files[0];
    setPartner({ ...partner, [name]: value });
    if (value != null) {
      if (name === "id_proof1") setIdProof1Preview(URL.createObjectURL(value));
      else if (name === "id_proof2")
        setIdProof2Preview(URL.createObjectURL(value));
      else if (name === "profile_photo")
        setProfilePhotoPreview(URL.createObjectURL(value))
      else setCoverPhotoPreview(URL.createObjectURL(value));
    }
  };

  const reoveImage = (name) => {
    setPartner({ ...partner, [name]: null });
  };

  const create = () => {
    setLoading(true);
    addUser(user)
      .then((res) => {
        partner.category = category;
        addPartner(res.id, partner);
        setLoading(false);
        closeModal();
      })
      .catch((err) => {
        setLoading(false);
        toast({          
          icon: 'envelope',          
          title: "Bad Request",
          description: JSON.stringify(err.body),
          time: 5000,
          color: 'red'
        });
      });
  };

  const update = () => {
    setLoading(true);
    if (partner.profile_photo?.toString().startsWith("http"))
      delete partner.profile_photo;
    if (partner.id_proof1?.toString().startsWith("http"))
      delete partner.id_proof1;
    if (partner.id_proof2?.toString().startsWith("http"))
      delete partner.id_proof2;
    if (partner.cover_photo?.toString().startsWith("http"))
      delete partner.cover_photo;
    delete partner.categories;
    partner.category = category;
    updatePartner(uid, partner)
      .then(() => {
        delete user.partner;
        updateUser(uid, user).then(() => {
          setLoading(false);
          closeModal();
        });
      })
      .catch((err) => {
        setLoading(false);
        toast({          
          icon: 'envelope',          
          title: "Bad Request",
          description: JSON.stringify(err.body),
          time: 5000,
          color: 'red'
        });
      });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUser((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };
  const handleParnetrInputChange = (e) => {
    const { name, value } = e.target;
    setPartner((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleCategoryDropdownChange = (event, data) => {
    setCategory(data.value);
  };
  return (
    <Form>
      <Grid.Row>
        <Segment>
          <Grid columns={2} relaxed="very">
            <Grid.Column>
              <Header as="h3">About</Header>
              <Form.Field>
                <label>Name</label>
                <input
                  placeholder="Name"
                  name="name"
                  value={user.name}
                  onChange={handleInputChange}
                />
              </Form.Field>
              <Form.Field>
                <label>Phone</label>
                <input
                  placeholder="Phone"
                  name="mobile_number"
                  value={user.mobile_number}
                  onChange={handleInputChange}
                />
              </Form.Field>
              <Form.Field>
                <label>Category</label>
                <Dropdown
                  placeholder="Select a category"
                  fluid
                  selection
                  name="category"
                  options={categories}
                  value={category}
                  onChange={handleCategoryDropdownChange}
                />
              </Form.Field>
              <Form.Field>
                <label>Business</label>
                <input
                  placeholder="Business"
                  name="business_name"
                  value={partner.business_name}
                  onChange={handleParnetrInputChange}
                />
              </Form.Field>
              <Form.Field>
                <label>About</label>
                <input
                  placeholder="About"
                  name="about_you"
                  value={partner.about_you}
                  onChange={handleParnetrInputChange}
                />
              </Form.Field>
              <Form.Field>
                <label>Experience</label>
                <input
                  placeholder="experience"
                  name="years_of_experience"
                  value={partner.years_of_experience}
                  onChange={handleInputChange}
                />
              </Form.Field>
              <Form.Field>
                <label>ProfilePhoto</label>
                {partner.profile_photo ? (
                  <div>
                    <img
                      src={profilePhotoPreview || partner.profile_photo}
                      style={{ height: "80px", width: "80px" }}
                      alt="category logo"
                    ></img>
                    <Button onClick={() => reoveImage("profile_photo")}>
                      <Icon name="trash"></Icon>
                    </Button>
                  </div>
                ) : (
                  <Input
                    type="file"
                    action
                    onChange={handleImageChange}
                    value={partner.profile_photo}
                    name="profile_photo"
                  ></Input>
                )}
              </Form.Field>
              <Form.Field>
                <Checkbox
                  label="Is Approved"
                  checked={partner.is_approved}
                  onChange={() => handleParnetrInputChange}
                />
              </Form.Field>
              <Form.Field>
                <label>AvailableCredits</label>
                <input
                  placeholder="AvailableCredits"
                  name="available_credits"
                  value={partner.available_credits}
                  onChange={handleParnetrInputChange}
                />
              </Form.Field>
              <Form.Field>
                <label>Cover Photo</label>
                {partner.cover_photo ? (
                  <div>
                    <img
                      src={coverPhotoPreview || partner.cover_photo}
                      style={{ height: "80px", width: "80px" }}
                      alt="category logo"
                    ></img>
                    <Button onClick={() => reoveImage("cover_photo")}>
                      <Icon name="trash"></Icon>
                    </Button>
                  </div>
                ) : (
                  <Input
                    type="file"
                    action
                    onChange={handleImageChange}
                    value={partner.cover_photo}
                    name="cover_photo"
                  ></Input>
                )}
              </Form.Field>
            </Grid.Column>
            <Grid.Column>
              <Grid.Row>
                <Header as="h3">Communication</Header>
                <Form.Field>
                  <label>Pincode</label>
                  <input
                    placeholder="Pincode"
                    name="pincode"
                    value={partner.pincode}
                    onChange={handleParnetrInputChange}
                  />
                </Form.Field>
                <Form.Field>
                  <label>Area</label>
                  <input
                    placeholder="Area"
                    name="business_area"
                    value={partner.business_area}
                    onChange={handleParnetrInputChange}
                  />
                </Form.Field>
                <Form.Field>
                  <label>Building</label>
                  <input
                    placeholder="Building"
                    name="business_building"
                    value={partner.business_building}
                    onChange={handleParnetrInputChange}
                  />
                </Form.Field>
                <Form.Field>
                  <label>Street</label>
                  <input
                    placeholder="Street"
                    name="business_street"
                    value={partner.business_street}
                    onChange={handleParnetrInputChange}
                  />
                </Form.Field>
                <Form.Field>
                  <label>City</label>
                  <input
                    placeholder="City"
                    name="business_city"
                    value={partner.business_city}
                    onChange={handleParnetrInputChange}
                  />
                </Form.Field>
                <Form.Field>
                  <label>State</label>
                  <input
                    placeholder="State"
                    name="business_state"
                    value={partner.business_state}
                    onChange={handleParnetrInputChange}
                  />
                </Form.Field>
                <Form.Field>
                  <label>Phone</label>
                  <input
                    placeholder="Phone"
                    name="phone_number"
                    value={partner.phone_number}
                    onChange={handleParnetrInputChange}
                  />
                </Form.Field>
                <Form.Field>
                  <label>Whatsapp</label>
                  <input
                    placeholder="Whatsapp"
                    name="whatsapp_number"
                    value={partner.whatsapp_number}
                    onChange={handleParnetrInputChange}
                  />
                </Form.Field>
              </Grid.Row>
              {user?.locations?.length > 0 && <div><Divider horizontal>AND</Divider>
               <Grid.Row>
                <Header>Place</Header>
                <Dropdown
                    placeholder="Select a location"
                    fluid
                    selection
                    options={user?.locations?.map((item,idx) => ({
                      key: idx,
                      text: `${item.name}, ${item.state}`,
                      value: idx
                    }))}  
                    value={[0,1,2,3]}                 
                    multiple
                  />
                {/* <Form.Field>
                  <label>District</label>
                  <Dropdown
                    placeholder="Select a district"
                    fluid
                    selection
                    options={districts}
                    value={partner.location_data?.district?.id}                    
                    search
                    disabled
                  />
                  <></>
                </Form.Field>
                <Form.Field>
                  <label>Area</label>
                  <Dropdown
                    placeholder="Select a place"
                    fluid
                    selection
                    options={places}
                    value={partner.location}
                    onChange={handleDropdownChange}
                    search
                  />
                  <></>
                </Form.Field> */}
              </Grid.Row></div>}
            </Grid.Column>
          </Grid>
          <Divider vertical>AND</Divider>
        </Segment>
      </Grid.Row>
      <Segment>
        <Header as="h3">Documents</Header>
        <Grid divided="vertically">
          <Grid.Row columns={2}>
            <Grid.Column>
              <Form.Field>
                <label>Proof1</label>
                {partner.id_proof1 ? (
                  <div>
                    <img
                      src={idProof1Preview || partner.id_proof1}
                      style={{ height: "80px", width: "80px" }}
                      alt="category logo"
                    ></img>
                    <Button onClick={() => reoveImage("id_proof1")}>
                      <Icon name="trash"></Icon>
                    </Button>
                  </div>
                ) : (
                  <Input
                    type="file"
                    action
                    onChange={handleImageChange}
                    value={partner.id_proof1}
                    name="id_proof1"
                  ></Input>
                )}
              </Form.Field>
            </Grid.Column>
            <Grid.Column>
              <Form.Field>
                <label>Proof2</label>
                {partner.id_proof2 ? (
                  <div>
                    <img
                      src={idProof2Preview || partner.id_proof2}
                      style={{ height: "80px", width: "80px" }}
                      alt="category logo"
                    ></img>
                    <Button onClick={() => reoveImage("id_proof2")}>
                      <Icon name="trash"></Icon>
                    </Button>
                  </div>
                ) : (
                  <Input
                    type="file"
                    action
                    onChange={handleImageChange}
                    value={partner.id_proof2}
                    name="id_proof2"
                  ></Input>
                )}
              </Form.Field>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
      <SemanticToastContainer position="botton-center" />
      {uid ? (
        <Button primary floated="right" type="submit" onClick={update}>
          {loading && <i className="loading spinner icon"></i>}Update
        </Button>
      ) : (
        <Button primary type="submit" floated="right" onClick={create}>
          {loading && <i className="loading spinner icon"></i>}Create
        </Button>
      )}
    </Form>
  );
};

export default Create;
