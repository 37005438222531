import React, { useEffect, useState } from "react";
import { Button, Form, Dropdown } from "semantic-ui-react";
import { addPlace, getDistricts } from "../../services/api/place";

const Place = ({ closeModal }) => {
  const [area, setArea] = useState("");
  const [district, setDistrict] = useState("");
  const [zipcode, setZipcode] = useState("");
  const [loading, setLoading] = useState(false);
  const [districts, setDistricts] = useState([]);

  useEffect(() => {
    getDistricts().then((res) => {
      const formattedOptions = res.results.map((item) => ({
        key: item.id,
        text: item.name,
        value: item.id,
      }));
      setDistricts(formattedOptions);
    });
  }, []);

  const create = () => {
    setLoading(true);
    addPlace({
      area: area,
      zipcode: zipcode,
      district: district,
    })
      .then(() => {
        setLoading(false);
        closeModal();
      })
      .catch((err) => console.log(err));
  };

  const handleDropdownChange = (event, data) => {
    setDistrict(data.value);
  };

  return (
    <Form>
      <Form.Field>
        <label>District</label>
        <Dropdown
          placeholder="Select a district"
          fluid
          selection
          options={districts}
          value={district}
          onChange={handleDropdownChange}
        />
      </Form.Field>
      <Form.Field>
        <label>Area</label>
        <input
          placeholder="area"
          value={area}
          onChange={(e) => setArea(e.target.value)}
        />
      </Form.Field>
      <Form.Field>
        <label>Zipcode</label>
        <input
          placeholder="zipcode"
          value={zipcode}
          onChange={(e) => setZipcode(e.target.value)}
        />
      </Form.Field>

      <Button primary type="submit" onClick={create}>
        {loading && <i className="loading spinner icon"></i>}Create
      </Button>
    </Form>
  );
};

export default Place;
