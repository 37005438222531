/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { SubCategory } from '../models/SubCategory';
import type { SubCategoryList } from '../models/SubCategoryList';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class SubcategoriesService {

    /**
     * @param category 
     * @param limit Number of results to return per page.
     * @param offset The initial index from which to return the results.
     * @returns any 
     * @throws ApiError
     */
    public static subcategoriesList(
category?: string,
limit?: number,
offset?: number,
): CancelablePromise<{
count: number;
next?: string | null;
previous?: string | null;
results: Array<SubCategoryList>;
}> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/subcategories/',
            query: {
                'category': category,
                'limit': limit,
                'offset': offset,
            },
        });
    }

    /**
     * @param id A unique integer value identifying this sub category.
     * @returns SubCategory 
     * @throws ApiError
     */
    public static subcategoriesRead(
id: number,
): CancelablePromise<SubCategory> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/subcategories/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id A unique integer value identifying this sub category.
     * @param data 
     * @returns SubCategory 
     * @throws ApiError
     */
    public static subcategoriesUpdate(
id: number,
data: SubCategory,
): CancelablePromise<SubCategory> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/subcategories/{id}/',
            path: {
                'id': id,
            },
            body: data,
        });
    }

    /**
     * @param id A unique integer value identifying this sub category.
     * @param data 
     * @returns SubCategory 
     * @throws ApiError
     */
    public static subcategoriesPartialUpdate(
id: number,
data: SubCategory,
): CancelablePromise<SubCategory> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/subcategories/{id}/',
            path: {
                'id': id,
            },
            body: data,
        });
    }

    /**
     * @param id A unique integer value identifying this sub category.
     * @returns void 
     * @throws ApiError
     */
    public static subcategoriesDelete(
id: number,
): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/subcategories/{id}/',
            path: {
                'id': id,
            },
        });
    }

}
