/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Referrals } from '../models/Referrals';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ReferralsService {

    /**
     * @param seacrh A search term.
     * @param limit Number of results to return per page.
     * @param offset The initial index from which to return the results.
     * @returns any 
     * @throws ApiError
     */
    public static referralsList(
search?: string,
limit?: number,
offset?: number,
): CancelablePromise<{
count: number;
next?: string | null;
previous?: string | null;
results: Array<Referrals>;
}> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/referrals/',
            query: {
                'limit': 2000,
                'offset': offset,
                'search': search
            },
        });
    }

}
