import React, { useState } from "react";
import { getToken } from "./services/api/security";

// Create AuthContext
export const AuthContext = React.createContext();

// AuthProvider component
export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const login = (uname, pwd) => {
    getToken({
      username: uname,
      password: pwd,
    }).then((res) => {
      localStorage.setItem("token", res.access);
      setIsAuthenticated(true);
    });
  };

  const logout = () => {
    // Perform logout logic
    setIsAuthenticated(false);
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};
