/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { User } from '../models/User';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class UsersService {

    /**
     * @param isPartner 
     * @param isCustomer 
     * @param limit Number of results to return per page.
     * @param offset The initial index from which to return the results.
     * @returns any 
     * @throws ApiError
     */
    public static usersList(
        search?: string,
isPartner?: string,
isCustomer?: string,
limit?: number,
offset?: number,
): CancelablePromise<{
count: number;
next?: string | null;
previous?: string | null;
results: Array<User>;
}> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/users/',
            query: {
                'search': search,
                'is_partner': isPartner,
                'is_customer': isCustomer,
                'limit': limit,
                'offset': offset,
            },
        });
    }

    /**
     * @param data 
     * @returns User 
     * @throws ApiError
     */
    public static usersCreate(
data: User,
): CancelablePromise<User> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/users/',
            body: data,
        });
    }

    /**
     * @param id A unique integer value identifying this user.
     * @returns User 
     * @throws ApiError
     */
    public static usersRead(
id: number,
): CancelablePromise<User> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/users/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id A unique integer value identifying this user.
     * @param data 
     * @returns User 
     * @throws ApiError
     */
    public static usersUpdate(
id: number,
data: User,
): CancelablePromise<User> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/users/{id}/',
            path: {
                'id': id,
            },
            body: data,
        });
    }

    /**
     * @param id A unique integer value identifying this user.
     * @param data 
     * @returns User 
     * @throws ApiError
     */
    public static usersPartialUpdate(
id: number,
data: User,
): CancelablePromise<User> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/users/{id}/',
            path: {
                'id': id,
            },
            body: data,
        });
    }

    public static usersRemarkUpdate(
        id: number,
        data: string,
        ): CancelablePromise<User> {
                return __request(OpenAPI, {
                    method: 'PATCH',
                    url: '/users/{id}/',
                    path: {
                        'id': id,
                    },
                    body: {"remarks":data},
                });
            }

    /**
     * @param id A unique integer value identifying this user.
     * @returns void 
     * @throws ApiError
     */
    public static usersDelete(
id: number,
): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/users/{id}/',
            path: {
                'id': id,
            },
        });
    }

}
