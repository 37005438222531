import React, { useState } from "react";
import {
  Button,
  Form,
  Grid,
  Header,
  Segment,
  Message,
  Icon,
} from "semantic-ui-react";
import { getToken } from "../../services/api/security";
import { useNavigate } from "react-router";

const Login = () => {
  const [uname, setUname] = useState("");
  const [pwd, setPwd] = useState("");
  const [isErr, setIsErr] = useState(false);
  let navigate = useNavigate();

  // Login function
  const handleLogin = (e) => {
    e.preventDefault();
    getToken({
      username: uname,
      password: pwd,
    })
      .then((res) => {
        localStorage.setItem("token", res.access);
        navigate("/stats");
      })
      .catch((err) => setIsErr(true));
  };

  return (
    <Grid textAlign="center" style={{ height: "100vh" }} verticalAlign="middle">
      <Grid.Column style={{ maxWidth: 450 }}>
        <Header as="h2" color="teal" textAlign="center">
          <Icon name="searchengin" circular /> SearchMyExpert
        </Header>
        <Form size="large" error={isErr}>
          <Segment stacked>
            <Message error content="username or password is invalid"></Message>
            <Form.Input
              fluid
              icon="user"
              iconPosition="left"
              placeholder="Username"
              value={uname}
              onChange={(e) => setUname(e.target.value)}
            />
            <Form.Input
              fluid
              icon="lock"
              iconPosition="left"
              placeholder="Password"
              type="password"
              value={pwd}
              onChange={(e) => setPwd(e.target.value)}
            />
            <Button
              type="submit"
              color="teal"
              fluid
              size="large"
              onClick={handleLogin}
            >
              Login
            </Button>
          </Segment>
        </Form>
      </Grid.Column>
    </Grid>
  );
};

export default Login;
