import React, { useEffect, useState } from "react";
import {
  Table,
  Pagination,
  Container,
  Checkbox,
  Icon,
  Menu,
} from "semantic-ui-react";
import { getReviews } from "../../services/api/review";

export default function ReviewList() {
  const [reviews, setReviews] = useState([]);
  const [activePage, setActivePage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const ITEMSPERPAGE = 5;

  useEffect(() => {
    getReviews().then((res) => {
      setReviews(res.results);
      setTotalPages(Math.ceil(res.count / ITEMSPERPAGE));
    });
  });

  const handlePageChange = (e, { activePage }) => {
    setActivePage(activePage);
  };

  const getPageData = () => {
    const startIndex = (activePage - 1) * ITEMSPERPAGE;
    const endIndex = startIndex + ITEMSPERPAGE;
    return reviews?.slice(startIndex, endIndex);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        position: "absolute",
        right: 0,
        top: 0,
        width: "90%",
      }}
    >
      <Container as={Menu} style={{ width: "100%" }}>
        <Menu.Item position="right">
          <Icon name="user circle" />
          Admin
        </Menu.Item>
      </Container>
      <Table style={{ margin: "10px" }} celled className="center aligned">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Review</Table.HeaderCell>
            <Table.HeaderCell>Rating</Table.HeaderCell>
            <Table.HeaderCell>User</Table.HeaderCell>
            <Table.HeaderCell>CreatedBy</Table.HeaderCell>
            <Table.HeaderCell>ISApproved</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {getPageData().map((review, idx) => (
            <Table.Row key={idx}>
              <Table.Cell>{review.review}</Table.Cell>
              <Table.Cell>{review.rating}</Table.Cell>
              <Table.Cell>
                <div>
                  <img
                    className="ui avatar image"
                    src={review.user?.profile_picture}
                    alt="category logo"
                  />
                  <span>{review.user?.name}</span>
                </div>
              </Table.Cell>
              <Table.Cell>
                <div>
                  <img
                    className="ui avatar image"
                    src={review.created_by?.profile_picture}
                    alt="category logo"
                  />
                  <span>{review.created_by?.name}</span>
                </div>
              </Table.Cell>
              <Table.Cell>
                <Checkbox checked={review.is_approved} />
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
      <Container textAlign="center">
        <Pagination
          className="right float"
          activePage={activePage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
        />
      </Container>
    </div>
  );
}
