import { Category, SubcategoriesService, CreateSubcategoryService } from "../openapi";

const {
  subcategoriesList,
  subcategoriesRead,
  subcategoriesUpdate,
  subcategoriesDelete,
} = SubcategoriesService;

const {
  createSubcategoryCreate
} = CreateSubcategoryService

export const getSubCategories = async (category:string) => {
  try {
    return await subcategoriesList(category);
  } catch (error) {
    throw new Error("error");
  }
};

export const getSubCategoryById = async (id: number): Promise<Category> => {
  try {
    return await subcategoriesRead(id);
  } catch (error) {
    throw new Error("error");
  }
};

export const addCategory = async (newCategory: Category): Promise<Category> => {
  try {
    return await createSubcategoryCreate(newCategory);
  } catch (error) {
    throw new Error("error");
  }
};

export const updateCategory = async (
  id: number,
  data: Category
): Promise<Category> => {
  try {
    return await subcategoriesUpdate(id, data);
  } catch (error) {
    throw new Error("error");
  }
};

export const deleteCategory = async (id: number) => {
  try {
    await subcategoriesDelete(id);
  } catch (error) {
    throw new Error("error");
  }
};
