import CategoryList from "./components/Category/CategoryList";
import UserList from "./components/User/UserList";
import PartnerList from "./components/Partner/PartnerList";
import LeadList from "./components/Lead/LeadList";
import ReviewList from "./components/Review/ReviewList";
import Login from "./components/Login/Login";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import PlaceList from "./components/Place/PlaceList";
import ReferralList from "./components/Referral/ReferralList";
import Dashboard from "./components/Dashboard/Dashboard";

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/login" Component={Login} />
        <Route path="/" element={
          <PrivateRoute><Dashboard/></PrivateRoute>
        } />
        <Route
        path="/stats"
        element={
          <PrivateRoute><Dashboard/></PrivateRoute>
        }/>
        <Route
          path="/user"
          element={
            <PrivateRoute>
              <UserList />
            </PrivateRoute>
          }
        />
        <Route
          path="/partner"
          element={
            <PrivateRoute>
              <PartnerList />
            </PrivateRoute>
          }
        />
        <Route
          path="/category"
          element={
            <PrivateRoute>
              <CategoryList />
            </PrivateRoute>
          }
        />
        <Route
          path="/referral"
          element={
            <PrivateRoute>
              <ReferralList />
            </PrivateRoute>
          }
        />
        <Route
          path="/place"
          element={
            <PrivateRoute>
              <PlaceList />
            </PrivateRoute>
          }
        />
        <Route
          path="/lead"
          element={
            <PrivateRoute>
              <LeadList />
            </PrivateRoute>
          }
        />
        <Route
          path="/review"
          element={
            <PrivateRoute>
              <ReviewList />
            </PrivateRoute>
          }
        />
      </Routes>
    </Router>
  );
};

export default App;
