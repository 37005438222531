import { TokenService, TokenObtainPair } from "../openapi";

const { tokenCreate } = TokenService;

export const getToken = async (
  data: TokenObtainPair
): Promise<TokenObtainPair> => {
  try {
    return await tokenCreate(data);
  } catch (error) {
    throw new Error("error");
  }
};
