import { User, Partner, UsersService, UserService, ApiError } from "../openapi";

const {
  usersList,
  usersCreate,
  usersRead,  
  usersDelete,  
  usersPartialUpdate,
  usersRemarkUpdate
} = UsersService;

const {
  userPartnerCreate,
  userPartnerUpdate
} = UserService

export const getUsers = async (term?:string) => {
  try {
    return await usersList(term,"false","true",2000,0);
  } catch (error) {
    throw new Error("error");
  }
};

export const getPartners = async (term?:string, limit?: number, offset?: number) => {
  try {
    return await usersList(term,"true","false",limit,offset);
  } catch (error) {
    throw new Error("error");
  }
};

export const getUserById = async (id: number): Promise<User> => {
  try {
    return await usersRead(id);
  } catch (error) {
    throw new Error("error");
  }
};

export const addUser = async (newUser: User): Promise<User> => {  
    return await usersCreate(newUser);  
};

export const addPartner = async (
  userId: string,
  newPartner: Partner
): Promise<Partner> => {  
    return await userPartnerCreate(userId, newPartner);
};

export const updateUser = async (id: number, data: User): Promise<User> => {  
    return await usersPartialUpdate(id, data);  
};

export const updatePartner = async (id: string, data: Partner): Promise<Partner> => {
  
    return await userPartnerUpdate(id, data);  
}

export const updateRemark = async (id: number, data: string): Promise<User> => {  
  return await usersRemarkUpdate(id, data);  
};

export const deleteUser = async (id: number) => {
  try {
    await usersDelete(id);
  } catch (error) {
    throw new Error("error");
  }
};
