import { useEffect, useState } from "react";
import {
  Table,
  Pagination,
  Container,
  Button,
  Icon,
  Menu,
  Search,
  Loader
} from "semantic-ui-react";
import { getReferrals } from "../../services/api/referral";
import ReferralBalanceModal from "./ReferralBalanceModal";
import _ from 'lodash';

export default function ReferralList() {
  const [currentRow, setCurrentRow] = useState();
  const [currentRowBal, setCurrentRowBal] = useState();
  const [showModel, setShowModel] = useState(false);
  const [referrals, setReferrals] = useState([]);
  const [activePage, setActivePage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [searchValue, setSearhValue] = useState('');
  const [loading, setLoading] = useState(true);
  const ITEMSPERPAGE = 25;

  const closeModal = () => {
    setShowModel(false);
  };

  useEffect(() => {
    getReferrals().then((res) => {
      setReferrals(res.results);
      setTotalPages(Math.ceil(res.count / ITEMSPERPAGE));
      setLoading(false);
    });
  }, [showModel]);

  const handlePageChange = (e, { activePage }) => {
    setActivePage(activePage);
  };

  const getPageData = () => {
    const startIndex = (activePage - 1) * ITEMSPERPAGE;
    const endIndex = startIndex + ITEMSPERPAGE;
    return referrals?.slice(startIndex, endIndex);
  };

  const openModal = (e) => {
    setCurrentRow(e.target.id);
    const currentRef = referrals.filter(
      (ref) => ref.referred_by.id == e.target.id
    );
    setCurrentRowBal(currentRef[0].referred_by.referral_balance);
    setShowModel(true);
  };

  const handleSearch = (value) => {
    setSearhValue(value); 
    delayedQuery(value);  
  }
  const delayedQuery = _.debounce((value) => {
    setIsLoading(true);    
    getReferrals(value).then((res) => {
      setReferrals(res.results);
      setTotalPages(Math.ceil(res.count / ITEMSPERPAGE));
      setIsLoading(false);
    });
  }, 500);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        position: "absolute",
        right: 0,
        top: 0,
        width: "90%",
      }}
    >
      <Container as={Menu} style={{ width: "100%" }}>
        <Menu.Item position="right">
          <Icon name="user circle" />
          Admin
        </Menu.Item>
      </Container>
      <Container style={{ width: "100%", alignSelf: "end", padding: "10px" }}>
      <Search            
            loading={isLoading}            
            onSearchChange={(e,{value}) =>{handleSearch(value)}}  
            value={searchValue}    
            showNoResults={false}
            placeholder="search referrals..."                  
          />
      </Container>
      { loading ? 
       <div style={{position:"fixed", top:"30%", left:"50%", zIndex:"1000"}}><Loader active content='Loading' size="large" /></div>
      : <div>
      <Table style={{ margin: "10px" }} celled className="center aligned">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>S.No</Table.HeaderCell>
            <Table.HeaderCell>User</Table.HeaderCell>
            <Table.HeaderCell>Credits</Table.HeaderCell>
            <Table.HeaderCell>ReferredBy (ReferralBal)</Table.HeaderCell>
            <Table.HeaderCell></Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {getPageData().map((referral, idx) => (
            <Table.Row key={idx}>
              <Table.Cell>{idx+1}</Table.Cell>
              <Table.Cell>
                {referral.user?.name}(
                {referral.user?.available_credits}) <br></br> {referral.user.mobile_number}
              </Table.Cell>
              <Table.Cell>{referral.credits}</Table.Cell>
              <Table.Cell>
                {referral.referred_by?.name} (
                {referral.referred_by?.referral_balance})<br></br>
                {referral.referred_by?.mobile_number}
              </Table.Cell>
              <Table.Cell>
                <Button
                  positive
                  id={referral.referred_by?.id}
                  onClick={openModal}
                >
                  Add Balance
                </Button>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
      <Container textAlign="center">
        <Pagination
          className="right float"
          activePage={activePage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
        />
      </Container>
      </div>}
      <ReferralBalanceModal
        open={showModel}
        closeModal={closeModal}
        id={currentRow}
        bal={currentRowBal}
      />
    </div>
  );
}
