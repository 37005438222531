import { useState, useEffect } from "react";
import { Modal, Select, Button, Grid } from "semantic-ui-react";
import { getPartners, getUsers } from "../../services/api/users";
import { getCategories } from "../../services/api/categories"
import { createLead } from "../../services/api/lead"
import _ from "lodash";
import { getPlaces } from "../../services/api/place";

const LeadModal = (props) => {

    const [users, setUsers] = useState([]);
    const [partners, setPartners] = useState([]);
    const [categories, setCategories] = useState([]);
    const [places, setPlaces] = useState([]);
    const [loading, setLoading] = useState(false);
    const [place, setPlace] = useState();
    const [category, setCategory] = useState();
    const [user, setUser] = useState();
    const [partner, setPartner] = useState();

    useEffect(() => {
        if(props.lead)
        {
            const lead = props.lead;
            setPartners([{ key: lead.partner?.id, value: lead.partner?.id, text: lead.partner?.name + ', ' + lead.partner?.mobile_number }])
            setUsers([{ key: lead.user?.id, value: lead.user?.id, text: lead.user?.name + ', ' + lead.user?.mobile_number }])
            setCategories([{ key: lead.category?.id, value: lead.category?.id, image: lead.category?.image, text: lead.category?.name }])
            setPlaces([{key: lead.place?.id, text: lead.place?.area + ', ' + lead.place?.district?.name + ', ' + lead.place?.zipcode, value: lead.place?.id}])
        }
        else
        {
            setPartners([])
            setUsers([])
            setCategories([])
            setPlaces([])
        }
    },[props.lead])

    const searchPartner = _.debounce((e) => {
        setLoading(true);
        getPartners(e.target.value).then((res) => {
            setPartners(res.results.map(r => ({ key: r.id, value: r.id, text: r.name + ', ' + r.mobile_number })))
            setLoading(false);
        });
    }, 500);

    const searchUser = _.debounce((e) => {
        setLoading(true);
        getUsers(e.target.value).then((res) => {
            setUsers(res.results.map(r => ({ key: r.id, value: r.id, text: r.name + ', ' + r.mobile_number })))
            setLoading(false);
        });
    }, 500);

    const searchCategory = _.debounce((e) => {
        setLoading(true);
        getCategories(e.target.value).then((res) => {
            setCategories(res.results.map(r => ({ key: r.id, value: r.id, image: r.image, text: r.name })))
            setLoading(false);
        });
    }, 500);

    const searchPlace = _.debounce((e) => {
        setLoading(true);
        getPlaces(e.target.value).then((res) => {
            setPlaces(res.results.map(r => ({
                key: r.id,
                text: r.area + ', ' + r.district.name + ', ' + r.zipcode,
                value: r.id,
            })))
            setLoading(false);
        });
    }, 500);

    const update = () => {
        let body = []
        partner.forEach(element => {
           body = [...body,{ partner: element,
            user: user,
            place: place,
            category: category}]
        });
        createLead(body).then(res => console.log(res));
        props.closeModal()
        }

    return (
        <Modal
            className="scrolling"
            size="small"
            open={props.open && props.closeModal}
            closeIcon
            onClose={props.closeModal}
        >
            <Modal.Header>Lead</Modal.Header>
            <Modal.Content>
                <Grid>
                    <Grid.Row>
                        <Select
                            placeholder='Select category'
                            fluid
                            search
                            selection
                            options={categories}
                            onSearchChange={searchCategory}
                            loading={loading}
                            onChange={(e,data) => setCategory(data.value)}
                            className="select-fix"     
                            defaultValue={props.lead?.category?.id}
                        />
                    </Grid.Row>
                    <Grid.Row>
                        <Select
                            placeholder='Select place'
                            fluid
                            search
                            selection
                            options={places}
                            onSearchChange={searchPlace}
                            loading={loading}
                            onChange={(e,data) => setPlace(data.value)}
                            className="select-fix"
                            defaultValue={props.lead?.place?.id}
                        />
                    </Grid.Row>
                    <Grid.Row>
                        <Select
                            placeholder='Select partner'
                            fluid
                            search
                            selection
                            options={partners}
                            onSearchChange={searchPartner}
                            loading={loading}
                            onChange={(e,data) => setPartner(data.value)}
                            className="select-fix"
                            multiple
                        />
                    </Grid.Row>
                    <Grid.Row>
                        <Select
                            placeholder='Select user'
                            fluid
                            search
                            selection
                            options={users}
                            onSearchChange={searchUser}
                            loading={loading}
                            onChange={(e,data) => setUser(data.value)}
                            className="select-fix"
                            defaultValue={props.lead?.user?.id}
                        />
                    </Grid.Row>
                    <Grid.Row>
                       <Button primary floated="right" onClick={update}>Add</Button>
                    </Grid.Row>
                </Grid>
            </Modal.Content>
        </Modal>
    );
};

export default LeadModal;