import React, { useEffect, useState } from "react";
import { Button, Form, Input, Icon, Checkbox, Modal } from "semantic-ui-react";
import { addDistrict } from "../../services/api/place";

const DistrictModal = (props) => {
  const [district, setDistrict] = useState("");
  const [loading, setLoading] = useState(false);

  const create = () => {
    setLoading(true);
    addDistrict({
      name: district,
    })
      .then(() => {
        setLoading(false);
        props.closeModal();
      })
      .catch((err) => console.log(err));
  };

  return (
    <Modal
      className="scrolling"
      size="tiny"
      open={props.open}
      closeIcon
      onClose={props.closeModal}
    >
      <Modal.Header>Place</Modal.Header>
      <Modal.Content>
        <Form>
          <Form.Field>
            <label>District</label>
            <input
              placeholder="district"
              value={district}
              onChange={(e) => setDistrict(e.target.value)}
            />
          </Form.Field>
          <Button primary type="submit" onClick={create}>
            {loading && <i className="loading spinner icon"></i>}Create
          </Button>
        </Form>
      </Modal.Content>
    </Modal>
  );
};

export default DistrictModal;
