/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Place } from '../models/Place';
import type { District } from '../models/District';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class PlacesService {

    /**
     * @param search A search term.
     * @param limit Number of results to return per page.
     * @param offset The initial index from which to return the results.
     * @returns any 
     * @throws ApiError
     */
    public static placesList(
search?: string,
limit?: number,
offset?: number,
): CancelablePromise<{
count: number;
next?: string | null;
previous?: string | null;
results: Array<Place>;
}> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/places/',
            query: {
                'search': search,
                'limit': 1000,
                'offset': offset,
            },
        });
    }

    public static districtsList(
        search?: string,
        limit?: number,
        offset?: number,
        ): CancelablePromise<{
        count: number;
        next?: string | null;
        previous?: string | null;
        results: Array<District>;
        }> {
                return __request(OpenAPI, {
                    method: 'GET',
                    url: '/districts/',
                    query: {
                        'search': search,
                        'limit': 1000,
                        'offset': offset,
                    },
                });
            }

    /**
     * @param data 
     * @returns Place 
     * @throws ApiError
     */
    public static placesCreate(
data: Place,
): CancelablePromise<Place> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/places/',
            body: data,
        });
    }

    public static districtCreate(
        data: District,
        ): CancelablePromise<District> {
                return __request(OpenAPI, {
                    method: 'POST',
                    url: '/districts/',
                    body: data,
                });
            }

    /**
     * @param id A unique integer value identifying this place.
     * @returns Place 
     * @throws ApiError
     */
    public static placesRead(
id: number,
): CancelablePromise<Place> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/places/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id A unique integer value identifying this place.
     * @param data 
     * @returns Place 
     * @throws ApiError
     */
    public static placesUpdate(
id: number,
data: Place,
): CancelablePromise<Place> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/places/{id}/',
            path: {
                'id': id,
            },
            body: data,
        });
    }

    /**
     * @param id A unique integer value identifying this place.
     * @param data 
     * @returns Place 
     * @throws ApiError
     */
    public static placesPartialUpdate(
id: number,
data: Place,
): CancelablePromise<Place> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/places/{id}/',
            path: {
                'id': id,
            },
            body: data,
        });
    }

    /**
     * @param id A unique integer value identifying this place.
     * @returns void 
     * @throws ApiError
     */
    public static placesDelete(
id: number,
): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/places/{id}/',
            path: {
                'id': id,
            },
        });
    }

    public static districtsDelete(
        id: number,
        ): CancelablePromise<void> {
                return __request(OpenAPI, {
                    method: 'DELETE',
                    url: '/district/{id}/',
                    path: {
                        'id': id,
                    },
                });
            }

}
