import { Category, CategoriesService } from "../openapi";

const {
  categoriesList,
  categoriesCreate,
  categoriesRead,  
  categoriesDelete,
  categoriesPartialUpdate
} = CategoriesService;

export const getCategories = async () => {
  try {
    return await categoriesList();
  } catch (error) {
    throw new Error("error");
  }
};

export const getCategoryById = async (id: number): Promise<Category> => {
  try {
    return await categoriesRead(id);
  } catch (error) {
    throw new Error("error");
  }
};

export const addCategory = async (newCategory: Category): Promise<Category> => {
  try {
    return await categoriesCreate(newCategory);
  } catch (error) {
    throw new Error("error");
  }
};

export const updateCategory = async (
  id: number,
  data: Category
): Promise<Category> => {
  try {
    return await categoriesPartialUpdate(id, data);
  } catch (error) {
    throw new Error("error");
  }
};

export const deleteCategory = async (id: number) => {
  try {
    await categoriesDelete(id);
  } catch (error) {
    throw new Error("error");
  }
};
