/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { SubCategory } from '../models/SubCategory';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CreateSubcategoryService {

    /**
     * @param data 
     * @returns SubCategory 
     * @throws ApiError
     */
    public static createSubcategoryCreate(
data: SubCategory,
): CancelablePromise<SubCategory> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/create_subcategory/',
            body: data,
        });
    }

}
