import React, { useEffect, useState } from "react";
import {
  Button,
  Table,
  Pagination,
  Container,
  Checkbox,
  Icon,
  Menu,
} from "semantic-ui-react";
import { getCategories } from "../../services/api/categories";
import CategoryModal from "./CategoryModel";
import ConfirmationModal from "./ConfirmationModel";

export default function CategoryList() {
  const [categories, setCategories] = useState([]);
  const [showModel, setShowModel] = useState(false);
  const [updateInfo, setUpdateInfo] = useState({});
  const [activePage, setActivePage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const ITEMSPERPAGE = 10;

  useEffect(() => {
    getCategories().then((res) => {
      setCategories(res.results);
      setTotalPages(Math.ceil(res.count / ITEMSPERPAGE));
    });
  }, [showModel, updateInfo]);

  const closeModal = () => {
    setShowModel(false);
  };

  const openUpdateModal = (cid) => {
    var updateObj = { showUpdateModel: true, category: cid };
    setUpdateInfo(updateObj);
  };

  const closeUpdateModal = () => {
    var updateObj = { showUpdateModel: false, category: null };
    setUpdateInfo(updateObj);
  };

  const openConfirmationModal = (cid) => {
    var delObj = { showDeleteConfirationModel: true, category: cid };
    setUpdateInfo(delObj);
  };

  const closeConfirmationModal = () => {
    var delObj = { showDeleteConfirationModel: false, category: null };
    setUpdateInfo(delObj);
    closeModal();
  };

  const handlePageChange = (e, { activePage }) => {
    setActivePage(activePage);
  };

  const getPageData = () => {
    const startIndex = (activePage - 1) * ITEMSPERPAGE;
    const endIndex = startIndex + ITEMSPERPAGE;
    return categories?.slice(startIndex, endIndex);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        position: "absolute",
        right: 0,
        top: 0,
        width: "90%",
      }}
    >
      <Container as={Menu} style={{ width: "100%" }}>
        <Menu.Item position="right">
          <Icon name="user circle" />
          Admin
        </Menu.Item>
      </Container>
      <Container style={{ width: "100%", alignSelf: "end", padding: "10px" }}>
        <Button primary onClick={() => setShowModel(true)} floated="right">
          Add
        </Button>
      </Container>
      <Container style={{ width: "100%", padding: "10px" }}>
        <Table celled className="center aligned">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>ID</Table.HeaderCell>
              <Table.HeaderCell>Name</Table.HeaderCell>
              <Table.HeaderCell>Featured</Table.HeaderCell>
              <Table.HeaderCell>Popular</Table.HeaderCell>
              <Table.HeaderCell>IsTop10</Table.HeaderCell>
              <Table.HeaderCell></Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {getPageData().map((category, idx) => (
              <Table.Row key={idx}>
                <Table.Cell>{category.id}</Table.Cell>
                <Table.Cell>
                  <div>
                    <img
                      className="ui avatar image"
                      src={category.image}
                      alt=""
                    />
                    <span>{category.name}</span>
                  </div>
                </Table.Cell>
                <Table.Cell>
                  <Checkbox checked={category.is_featured} />
                </Table.Cell>
                <Table.Cell>
                  <Checkbox checked={category.is_popular} />
                </Table.Cell>
                <Table.Cell>
                  <Checkbox checked={category.is_top_ten} />
                </Table.Cell>
                <Table.Cell>
                  <Button
                    icon
                    id={category.id}
                    onClick={(e) => openUpdateModal(e.target.id)}
                    positive
                  >
                    <Icon id={category.id} name="edit" />
                  </Button>
                  <Button
                    icon
                    id={category.id}
                    onClick={(e) => openConfirmationModal(e.target.id)}
                    negative
                  >
                    <Icon id={category.id} name="trash alternate" />
                  </Button>
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </Container>
      <Container textAlign="center">
        <Pagination
          className="right float"
          activePage={activePage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
        />
      </Container>
      <CategoryModal open={showModel} closeModal={closeModal} />
      <CategoryModal
        open={updateInfo.showUpdateModel}
        closeModal={closeUpdateModal}
        cid={updateInfo.category}
      />
      <ConfirmationModal
        open={updateInfo.showDeleteConfirationModel}
        closeModal={closeConfirmationModal}
        cid={updateInfo.category}
      />
    </div>
  );
}
