/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Leads, LeadCreate } from '../models/Leads';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class LeadsService {

    /**
     * @param limit Number of results to return per page.
     * @param offset The initial index from which to return the results.
     * @returns any 
     * @throws ApiError
     */
    public static leadsList(
limit?: number,
offset?: number,
): CancelablePromise<{
count: number;
next?: string | null;
previous?: string | null;
results: Array<Leads>;
}> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/leads/',
            query: {
                'limit': limit,
                'offset': offset,
            },
        });
    }

    /**
     * @param data 
     * @returns Leads 
     * @throws ApiError
     */
    public static leadsCreate(
data: Array<LeadCreate>,
): CancelablePromise<Array<LeadCreate>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/bulk_create_leads/',
            body: data,
        });
    }

    /**
     * @param id 
     * @returns Leads 
     * @throws ApiError
     */
    public static leadsRead(
id: string,
): CancelablePromise<Leads> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/leads/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id 
     * @param data 
     * @returns Leads 
     * @throws ApiError
     */
    public static leadsUpdate(
id: string,
data: Leads,
): CancelablePromise<Leads> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/leads/{id}/',
            path: {
                'id': id,
            },
            body: data,
        });
    }

    /**
     * @param id 
     * @param data 
     * @returns Leads 
     * @throws ApiError
     */
    public static leadsPartialUpdate(
id: string,
data: Leads,
): CancelablePromise<Leads> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/leads/{id}/',
            path: {
                'id': id,
            },
            body: data,
        });
    }

    /**
     * @param id 
     * @returns void 
     * @throws ApiError
     */
    public static leadsDelete(
id: string,
): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/leads/{id}/',
            path: {
                'id': id,
            },
        });
    }

}
