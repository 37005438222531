import { useEffect, useState } from "react";
import {
  Table,
  Pagination,
  Container,
  Checkbox,
  Icon,
  Menu,
  Loader,
  Button
} from "semantic-ui-react";
import { getLeads } from "../../services/api/lead";
import LeadModal from "./LeadModal";

export default function LeadList() {
  const [leads, setLeads] = useState([]);
  const [activePage, setActivePage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModel] = useState(false);
  const [currLead, setCurrLead] = useState();
  const ITEMSPERPAGE = 25;

  useEffect(() => {
    getLeads(ITEMSPERPAGE,0).then((res) => {
      setLeads(res.results);
      setTotalPages(Math.ceil(res.count / ITEMSPERPAGE));
      setLoading(false);
    });
  }, []);

  const handlePageChange = (e, { activePage }) => {
    setActivePage(activePage);
    getLeads(ITEMSPERPAGE,(activePage-1) * ITEMSPERPAGE).then((res) => {
      setLeads(res.results);
      setTotalPages(Math.ceil(res.count / ITEMSPERPAGE));
      setLoading(false);
    });
  };

  const closeModal = () => {
    setShowModel(false);
  };

  const onEditclick = (id) => {
    setCurrLead(leads.find(lead => lead.id==id))
    setShowModel(true)
  }

  const onAddClick = () => {
    setCurrLead(null)
    setShowModel(true)
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        position: "absolute",
        right: 0,
        top: 0,
        width: "90%",
      }}
    >
      <Container as={Menu} style={{ width: "100%" }}>
        <Menu.Item position="right">
          <Icon name="user circle" />
          Admin
        </Menu.Item>
      </Container>
      <Container style={{ width: "100%", alignSelf: "end", padding: "10px" }}>
        <Button primary onClick={onAddClick} floated="right">
          Add
        </Button>
      </Container>
      { loading ? 
       <div style={{position:"fixed", top:"30%", left:"50%", zIndex:"1000"}}><Loader active content='Loading' size="large" /></div>
      : <div>
      <Table style={{ margin: "10px" }} celled className="center aligned">
        <Table.Header>
          <Table.Row>
          <Table.HeaderCell>S.No</Table.HeaderCell>
            <Table.HeaderCell>User</Table.HeaderCell>
            <Table.HeaderCell>Partner</Table.HeaderCell>
            <Table.HeaderCell>Category</Table.HeaderCell>
            <Table.HeaderCell>Area</Table.HeaderCell>
            <Table.HeaderCell>Created</Table.HeaderCell>
            <Table.HeaderCell>Enquiry</Table.HeaderCell>
            <Table.HeaderCell>Locked</Table.HeaderCell>
            <Table.HeaderCell></Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {leads.map((lead, idx) => (
            <Table.Row key={idx}>
              <Table.Cell>{idx+((activePage-1)*ITEMSPERPAGE)+1}</Table.Cell>
              <Table.Cell>{lead.user?.name} ({lead.user?.mobile_number})</Table.Cell>
              <Table.Cell>{lead.partner?.name} ({lead.partner?.mobile_number})</Table.Cell>
              <Table.Cell>{lead.category?.name}</Table.Cell>
              <Table.Cell>{lead.place?.area}</Table.Cell>
              <Table.Cell>{new Date(lead.created).toLocaleDateString()}</Table.Cell>
              <Table.Cell>{lead.enquiry}</Table.Cell>
              <Table.Cell>
                <Checkbox checked={lead.is_locked} />
              </Table.Cell>
              <Table.Cell>
                <Button
                    icon
                    id={lead.id}                    
                    positive
                    onClick={e => onEditclick(e.target.id)}
                  >
                    <Icon name="copy outline" id={lead.id}/>
                  </Button>
                  </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
      <Container textAlign="center">
        <Pagination
          className="right float"
          activePage={activePage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
        />
      </Container>
      </div>}
      <LeadModal open={showModal} closeModal={closeModal} lead={currLead}/>
    </div>
  );
}
