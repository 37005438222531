import { useState } from "react";
import { Modal, Form, Button } from "semantic-ui-react";
import { updateUser } from "../../services/api/users";

const ReferralBalanceModal = (props) => {
  const [balance, setBalance] = useState(0);
  const [loading, setLoading] = useState(false);

  const updateBalance = () => {
    setLoading(true);
    const newBalance = parseInt(balance) + parseInt(props.bal);
    const data = {
      referral_balance: newBalance,
    };
    updateUser(props.id, data)
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
    props.closeModal();
  };
  return (
    <Modal
      className="scrolling"
      size="tiny"
      open={props.open}
      closeIcon
      onClose={props.closeModal}
    >
      <Modal.Header>Add Balance To Referral Balance</Modal.Header>
      <Modal.Content>
        <Form>
          <Form.Field>
            <label>Existing Balance</label>
            <input disabled value={props.bal} />
          </Form.Field>
          <Form.Field required={true}>
            <label>Amount</label>
            <input
              placeholder="Enter the amount to be added to balance"
              onChange={(e) => setBalance(e.target.value)}
            />
          </Form.Field>
          <Button type="submit" onClick={updateBalance} primary floated="right">
            {loading && <i className="loading spinner icon"></i>}Add To Balnce
          </Button>
        </Form>
      </Modal.Content>
    </Modal>
  );
};

export default ReferralBalanceModal;
