/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Partner } from '../models/Partner';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class UserService {

    /**
     * @param userId 
     * @returns Partner 
     * @throws ApiError
     */
    public static userPartnerRead(
userId: string,
): CancelablePromise<Partner> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/user/{user_id}/partner/',
            path: {
                'user_id': userId,
            },
        });
    }

    /**
     * @param userId 
     * @param data 
     * @returns Partner 
     * @throws ApiError
     */
    public static userPartnerCreate(
userId: string,
data: Partner,
): CancelablePromise<Partner> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/user/{user_id}/partner/',
            path: {
                'user_id': userId,
            },
            formData: data,
        });
    }

    /**
     * @param userId 
     * @param data 
     * @returns Partner 
     * @throws ApiError
     */
    public static userPartnerUpdate(
userId: string,
data: Partner,
): CancelablePromise<Partner> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/user/{user_id}/partner/',
            path: {
                'user_id': userId,
            },
            formData: data,
        });
    }

    /**
     * @param userId 
     * @param limit Number of results to return per page.
     * @param offset The initial index from which to return the results.
     * @returns any 
     * @throws ApiError
     */
    public static userReviewsList(
userId: string,
limit?: number,
offset?: number,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/user/{user_id}/reviews/',
            path: {
                'user_id': userId,
            },
            query: {
                'limit': limit,
                'offset': offset,
            },
        });
    }

    /**
     * @param userId 
     * @returns any 
     * @throws ApiError
     */
    public static userReviewsCreate(
userId: string,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/user/{user_id}/reviews/',
            path: {
                'user_id': userId,
            },
        });
    }

    /**
     * @param id A unique integer value identifying this review.
     * @param userId 
     * @returns any 
     * @throws ApiError
     */
    public static userReviewsRead(
id: number,
userId: string,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/user/{user_id}/reviews/{id}/',
            path: {
                'id': id,
                'user_id': userId,
            },
        });
    }

    /**
     * @param id A unique integer value identifying this review.
     * @param userId 
     * @returns any 
     * @throws ApiError
     */
    public static userReviewsUpdate(
id: number,
userId: string,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/user/{user_id}/reviews/{id}/',
            path: {
                'id': id,
                'user_id': userId,
            },
        });
    }

    /**
     * @param id A unique integer value identifying this review.
     * @param userId 
     * @returns any 
     * @throws ApiError
     */
    public static userReviewsPartialUpdate(
id: number,
userId: string,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/user/{user_id}/reviews/{id}/',
            path: {
                'id': id,
                'user_id': userId,
            },
        });
    }

    /**
     * @param id A unique integer value identifying this review.
     * @param userId 
     * @returns void 
     * @throws ApiError
     */
    public static userReviewsDelete(
id: number,
userId: string,
): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/user/{user_id}/reviews/{id}/',
            path: {
                'id': id,
                'user_id': userId,
            },
        });
    }

}
