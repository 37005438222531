/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AdminReview } from '../models/AdminReview';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ReviewsService {

    /**
     * @param isApproved 
     * @param limit Number of results to return per page.
     * @param offset The initial index from which to return the results.
     * @returns any 
     * @throws ApiError
     */
    public static reviewsList(
isApproved?: string,
limit?: number,
offset?: number,
): CancelablePromise<{
count: number;
next?: string | null;
previous?: string | null;
results: Array<AdminReview>;
}> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/reviews/',
            query: {
                'is_approved': isApproved,
                'limit': limit,
                'offset': offset,
            },
        });
    }

    /**
     * @param data 
     * @returns AdminReview 
     * @throws ApiError
     */
    public static reviewsCreate(
data: AdminReview,
): CancelablePromise<AdminReview> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/reviews/',
            body: data,
        });
    }

    /**
     * @param id A unique integer value identifying this review.
     * @returns AdminReview 
     * @throws ApiError
     */
    public static reviewsRead(
id: number,
): CancelablePromise<AdminReview> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/reviews/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id A unique integer value identifying this review.
     * @param data 
     * @returns AdminReview 
     * @throws ApiError
     */
    public static reviewsUpdate(
id: number,
data: AdminReview,
): CancelablePromise<AdminReview> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/reviews/{id}/',
            path: {
                'id': id,
            },
            body: data,
        });
    }

    /**
     * @param id A unique integer value identifying this review.
     * @param data 
     * @returns AdminReview 
     * @throws ApiError
     */
    public static reviewsPartialUpdate(
id: number,
data: AdminReview,
): CancelablePromise<AdminReview> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/reviews/{id}/',
            path: {
                'id': id,
            },
            body: data,
        });
    }

    /**
     * @param id A unique integer value identifying this review.
     * @returns void 
     * @throws ApiError
     */
    public static reviewsDelete(
id: number,
): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/reviews/{id}/',
            path: {
                'id': id,
            },
        });
    }

}
