import { AdminReview, ReviewsService  } from "../openapi";

const {
  reviewsList,
  reviewsRead,
  reviewsPartialUpdate
} = ReviewsService;

export const getReviews = async () => {
  try {
    return await reviewsList();
  } catch (error) {
    throw new Error("error");
  }
};

export const getReviewById = async (id: number): Promise<AdminReview> => {
  try {
    return await reviewsRead(id);
  } catch (error) {
    throw new Error("error");
  }
};

export const updateReview = async (
  id: number,
  data: AdminReview
): Promise<AdminReview> => {
  try {
    return await reviewsPartialUpdate(id, data);
  } catch (error) {
    throw new Error("error");
  }
};

