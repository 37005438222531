import { Place, PlacesService } from "../openapi";
import { District } from "../openapi/models/District";

const {
  placesList,
  placesCreate,
  placesDelete,
  placesRead,
  districtCreate,
  districtsDelete,
  districtsList
} = PlacesService;

export const getPlaces = async (term?: string) => {
  try {
    return await placesList(term);
  } catch (error) {
    throw new Error("error");
  }
};

export const getDistricts = async () => {
  try {
    return await districtsList();
  } catch (error) {
    throw new Error("error");
  }
};

export const addPlace = async (newPlace: Place): Promise<Place> => {
  try {
    return await placesCreate(newPlace);
  } catch (error) {
    throw new Error("error");
  }
};

export const addDistrict = async (newDistrict: District): Promise<District> => {
  try {
    return await districtCreate(newDistrict);
  } catch (error) {
    throw new Error("error");
  }
};

export const getPlaceById = async (id: number): Promise<Place> => {
  try {
    return await placesRead(id);
  } catch (error) {
    throw new Error("error");
  }
};

export const deletePlace = async (id: number) => {
  try {
    await placesDelete(id);
  } catch (error) {
    throw new Error("error");
  }
};

