import { Referrals, ReferralsService } from "../openapi";

const { referralsList } = ReferralsService;

export const getReferrals = async (term?:string) => {
  try {
    return await referralsList(term);
  } catch (error) {
    throw new Error("error");
  }
};
