import { StatisticsService } from "../openapi";

const {
    statisticsList
} = StatisticsService;

export const getStats = async () => {
  try {
    return await statisticsList();
  } catch (error) {
    throw new Error("error");
  }
};