import React, { useEffect, useState } from "react";
import {
  Button,
  Form,
  Input,
  Icon,
  Checkbox,
  Dropdown,
  Modal,
} from "semantic-ui-react";
import { getCategories, getCategoryById } from "../../services/api/categories";
import { updatePartner, getUserById } from "../../services/api/users";
import { getSubCategories } from "../../services/api/subcategories";

const SubCategoryModal = ({ open, uid, closeModal }) => {
  const [category, setCategory] = useState("");
  const [subCategory, setSubCategory] = useState("");
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getCategories().then((res) => {
      const formattedOptions = res.results.map((item) => ({
        key: item.id,
        text: item.name,
        value: item.id,
      }));
      setCategories(formattedOptions);
    });    
  }, [uid]);

  const updateUser = () => {
    setLoading(true);
    getUserById(uid).then(res => {
      console.log(res)
      updatePartner(uid,{
        categories: [...res.partner?.categories.map(c => c.id),subCategory]
      })
        .then(() => {
          setLoading(false);
          closeModal();
        })
        .catch((err) => console.log(err));
    }).catch((err) => console.log(err));
    
  };

  const handleCategoryChange = (event, data) => {
    setCategory(data.value);
    getSubCategories(data.value).then((res) => {
      const formattedOptions = res.results.map((item) => ({
        key: item.id,
        text: item.name,
        value: item.id,
      }));
      setSubCategories(formattedOptions);
    });
  };

  const handleSubCategroyChange = (event, data) => {
    setSubCategory(data.value);
  };

  return (
    <Modal
      className="scrolling"
      size="tiny"
      open={open}
      closeIcon
      onClose={closeModal}
    >
      <Modal.Header>subCategory</Modal.Header>
      <Modal.Content>
        <Form>
          <Form.Field>
            <label>Category</label>
            <Dropdown
              placeholder="Select a category"
              fluid
              selection
              options={categories}
              value={category}
              onChange={handleCategoryChange}
            />
          </Form.Field>
          <Form.Field>
            <label>Sub Category</label>
            <Dropdown
              placeholder="Select a sub-category"
              fluid
              selection
              options={subCategories}
              value={subCategory}
              onChange={handleSubCategroyChange}
            />
          </Form.Field>
          <Button type="submit" onClick={updateUser}>
            {loading && <i className="loading spinner icon"></i>}Update
          </Button>
        </Form>
      </Modal.Content>
    </Modal>
  );
};

export default SubCategoryModal;
