import { Modal } from "semantic-ui-react";
import User from "./User";

const UserModal = (props) => {
  return (
    <Modal
      className="scrolling"
      size="small"
      open={props.open}
      closeIcon
      onClose={props.closeModal}
    >
      <Modal.Header>User</Modal.Header>
      <Modal.Content>
        <User uid={props.uid} closeModal={props.closeModal} />
      </Modal.Content>
    </Modal>
  );
};

export default UserModal;
