/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Category } from '../models/Category';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CategoriesService {

    /**
     * @param limit Number of results to return per page.
     * @param offset The initial index from which to return the results.
     * @returns any 
     * @throws ApiError
     */
    public static categoriesList(
limit?: number,
offset?: number,
): CancelablePromise<{
count: number;
next?: string | null;
previous?: string | null;
results: Array<Category>;
}> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/categories/',
            query: {
                'limit': 1000,
                'offset': offset,
            },
        });
    }

    /**
     * @param data 
     * @returns Category 
     * @throws ApiError
     */
    public static categoriesCreate(
data: Category,
): CancelablePromise<Category> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/categories/',
            formData: data,
        });
    }

    /**
     * @param id A unique integer value identifying this category.
     * @returns Category 
     * @throws ApiError
     */
    public static categoriesRead(
id: number,
): CancelablePromise<Category> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/categories/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id A unique integer value identifying this category.
     * @param data 
     * @returns Category 
     * @throws ApiError
     */
    public static categoriesUpdate(
id: number,
data: Category,
): CancelablePromise<Category> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/categories/{id}/',
            path: {
                'id': id,
            },
            formData: data,
        });
    }

    /**
     * @param id A unique integer value identifying this category.
     * @param data 
     * @returns Category 
     * @throws ApiError
     */
    public static categoriesPartialUpdate(
id: number,
data: Category,
): CancelablePromise<Category> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/categories/{id}/',
            path: {
                'id': id,
            },
            formData: data,
        });
    }

    /**
     * @param id A unique integer value identifying this category.
     * @returns void 
     * @throws ApiError
     */
    public static categoriesDelete(
id: number,
): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/categories/{id}/',
            path: {
                'id': id,
            },
        });
    }

}
