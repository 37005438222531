import { Modal } from "semantic-ui-react";
import Place from "./Place";

const PlaceModel = (props) => {
  return (
    <Modal
      className="scrolling"
      size="tiny"
      open={props.open}
      closeIcon
      onClose={props.closeModal}
    >
      <Modal.Header>Place</Modal.Header>
      <Modal.Content>
        <Place cid={props.cid} closeModal={props.closeModal} />
      </Modal.Content>
    </Modal>
  );
};

export default PlaceModel;
