import React, { useEffect, useState } from "react";
import {
  Button,
  Table,
  Pagination,
  Container,
  Search,
  Icon,
  Menu,
} from "semantic-ui-react";
import { getPlaces } from "../../services/api/place";
import PlaceModal from "./PlaceModel";
import DistrictModal from "./DistrictModal";
import ConfirmationModal from "./ConfirmationModel";
import _ from 'lodash';

export default function PlaceList() {
  const [places, setPlaces] = useState([]);
  const [showModel, setShowModel] = useState(false);
  const [showDistrictModel, setShowDistrictModel] = useState(false);
  const [updateInfo, setUpdateInfo] = useState({});
  const [activePage, setActivePage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [searchValue, setSearhValue] = useState('');
  const ITEMSPERPAGE = 10;

  useEffect(() => {
    getPlaces().then((res) => {
      setPlaces(res.results);
      setTotalPages(Math.ceil(res.count / ITEMSPERPAGE));
    });
  }, [showModel, updateInfo]);

  const closeModal = () => {
    setShowDistrictModel(false);
    setShowModel(false);
  };

  const openConfirmationModal = (cid) => {
    var delObj = { showDeleteConfirationModel: true, category: cid };
    setUpdateInfo(delObj);
  };

  const closeConfirmationModal = () => {
    var delObj = { showDeleteConfirationModel: false, category: null };
    setUpdateInfo(delObj);
    closeModal();
  };

  const handlePageChange = (e, { activePage }) => {
    setActivePage(activePage);
  };

  const getPageData = () => {
    const startIndex = (activePage - 1) * ITEMSPERPAGE;
    const endIndex = startIndex + ITEMSPERPAGE;
    return places?.slice(startIndex, endIndex);
  };

  const handleSearch = (value) => {
    setSearhValue(value); 
    delayedQuery(value);  
  }
  const delayedQuery = _.debounce((value) => {
    setIsLoading(true);    
    getPlaces(value).then((res) => {
      setPlaces(res.results);
      setTotalPages(Math.ceil(res.count / ITEMSPERPAGE));
      setIsLoading(false);
    });
  }, 500);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        position: "absolute",
        right: 0,
        top: 0,
        width: "90%",
      }}
    >
      <Container as={Menu} style={{ width: "100%" }}>
        <Menu.Item position="right">
          <Icon name="user circle" />
          Admin
        </Menu.Item>
      </Container>
      <Container style={{ width: "100%", alignSelf: "end", padding: "10px" }}>
      <Search            
            loading={isLoading}            
            onSearchChange={(e,{value}) =>{handleSearch(value)}}  
            value={searchValue} 
            showNoResults={false}
            placeholder="seacr places..."                     
          />
        <Button primary onClick={() => setShowModel(true)} floated="right">
          Add
        </Button>
        <Button
          primary
          onClick={() => setShowDistrictModel(true)}
          floated="right"
        >
          Add District
        </Button>
      </Container>
      <Container style={{ width: "100%", padding: "10px" }}>
        <Table celled className="center aligned">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>ID</Table.HeaderCell>
              <Table.HeaderCell>District</Table.HeaderCell>
              <Table.HeaderCell>Area</Table.HeaderCell>
              <Table.HeaderCell>ZipCode</Table.HeaderCell>
              <Table.HeaderCell></Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {getPageData().map((place, idx) => (
              <Table.Row key={idx}>
                <Table.Cell>{((activePage-1) * ITEMSPERPAGE)+idx + 1}</Table.Cell>
                <Table.Cell>{place.district?.name}</Table.Cell>
                <Table.Cell>{place.area}</Table.Cell>
                <Table.Cell>{place.zipcode}</Table.Cell>
                <Table.Cell>
                  <Button
                    icon
                    id={place.id}
                    onClick={(e) => openConfirmationModal(e.target.id)}
                    negative
                  >
                    <Icon id={place.id} name="trash alternate" />
                  </Button>
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </Container>
      <Container textAlign="center">
        <Pagination
          className="right float"
          activePage={activePage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
        />
      </Container>
      <PlaceModal open={showModel} closeModal={closeModal} />
      <DistrictModal open={showDistrictModel} closeModal={closeModal} />
      <ConfirmationModal
        open={updateInfo.showDeleteConfirationModel}
        closeModal={closeConfirmationModal}
        cid={updateInfo.category}
      />
    </div>
  );
}
