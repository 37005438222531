import { Navigate, Link, useNavigate } from "react-router-dom";
import { Menu, Sidebar, Icon } from "semantic-ui-react";
import "./index.css";

export default function PrivateRoute({ children }) {
  let navigate = useNavigate();

  if (!localStorage.getItem("token")) {
    return <Navigate to="/login" replace />;
  }

  return (
    <div style={{ display: "flex" }}>
      <Sidebar
        as={Menu}
        animation="push"
        icon="labeled"
        vertical
        visible={true}
        width="thin"
        style={{
          position: "fixed",
          boxShadow: "none",
          minHeight: "100vh",
          width: "10%",
        }}
      >
        <Menu.Item>
          <Icon size="mini" name="searchengin" /> SearchMyExpert
        </Menu.Item>
        <Menu.Item
          as={Link}
          to="/stats"
          icon="dashboard"
          name="dashboard"
        ></Menu.Item>
        <Menu.Item
          as={Link}
          to="/partner"
          icon="handshake"
          name="partner"
        ></Menu.Item>
        <Menu.Item as={Link} to="/user" icon="users" name="user"></Menu.Item>
        <Menu.Item
          as={Link}
          name="category"
          icon="cog"
          to="/category"
        ></Menu.Item>
        <Menu.Item
          as={Link}
          name="Referral"
          icon="cogs"
          to="/referral"
        ></Menu.Item>
        <Menu.Item
          as={Link}
          name="place"
          icon="location arrow"
          to="/place"
        ></Menu.Item>
        <Menu.Item as={Link} name="lead" icon="random" to="/lead"></Menu.Item>
        <Menu.Item
          as={Link}
          name="review"
          icon="chart line"
          to="/review"
        ></Menu.Item>
        <Menu.Item
          className="bottom-item"
          onClick={() => {
            localStorage.removeItem("token");
            navigate("../login", { replace: true });
          }}
        >
          <Icon name="log out" />
          Logout
        </Menu.Item>
      </Sidebar>
      <div>{children}</div>
    </div>
  );
}
