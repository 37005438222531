import { Modal } from "semantic-ui-react";
import { deletePlace } from "../../services/api/place";

const ConfirmationModal = ({ cid, open, closeModal }) => {
  const remove = () => {
    deletePlace(cid)
      .then((res) => {
        closeModal();
      })
      .catch((err) => console.log(err));
  };

  return (
    <Modal
      className="scrolling"
      size="tiny"
      open={open}
      closeIcon
      onClose={closeModal}
    >
      <Modal.Header>Are you sure to delete the place?</Modal.Header>
      <Modal.Content>
        <div
          className="ui buttons right floated"
          style={{ marginBottom: "10px" }}
        >
          <button className="ui button active" onClick={closeModal}>
            Cancel
          </button>
          <div className="or"></div>
          <button className="ui negative button" onClick={remove}>
            Confirm
          </button>
        </div>
      </Modal.Content>
    </Modal>
  );
};

export default ConfirmationModal;
